var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "highlight tooltip" }, [
    _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
    _c("ins", { class: _vm.image }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }