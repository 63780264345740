var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", {
    class: _vm.name,
    attrs: {
      viewBox: _vm.iconData.artboard ? _vm.iconData.artboard : "0 0 50 50",
      version: "1.1",
      preserveAspectRatio: "xMinYMin",
      role: "img",
    },
    domProps: { innerHTML: _vm._s(_vm.iconData.svg) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }